export const loadGoogleMapsScript = () => {
    return new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve();
      } else {
        const existingScript = document.querySelector(
          `script[src="https://maps.googleapis.com/maps/api/js?key=${window.googleMapsAPIKey}&libraries=places"]`
        );
  
        if (!existingScript) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${window.googleMapsAPIKey}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.setAttribute('loading', 'async');
          script.onload = () => {
            resolve();
          };
          script.onerror = () => {
            reject();
          };
          document.head.appendChild(script);
        } else {
          existingScript.async = true;
          existingScript.defer = true;
          existingScript.setAttribute('loading', 'async');
          existingScript.onload = () => resolve();
          existingScript.onerror = () => reject();
        }
      }
    });
  };