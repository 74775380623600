import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as Styled from 'jobDescriptionMap_styled';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import customMarker from '../../../../../../../public/assets/img/Apploi-TomTom-Location-Indicator.svg';
import { loadGoogleMapsScript } from '../../../GoogleMapUtils';

const JobDescriptionMap = (props) => {
  const { t } = useTranslation('jobDescription');
  const [identitySet, setIdentitySet] = useState(false);
  const [mapType, setMapType] = useState(null);
  const [error, setError] = useState(false);
  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);
  const mapElement = useRef();
  const ldClient = useLDClient();
  const flags = useFlags();

  const center = {
    lat: props?.jobDescription?.latitude || 40.73061,
    lng: props?.jobDescription?.longitude || -73.935242,
  };

  const config = {
    height: '300px',
    maxHeight: '300px',
  };

  const url = `https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`;

  useEffect(() => {
    const userID = props?.jobDescription?.team?.parent_id || props?.jobDescription?.team_id;
    if (userID) {
      ldClient
        .identify({ key: userID })
        .then(() => {
          setIdentitySet(true);
        })
        .catch((error) => {
          console.error('Error setting LaunchDarkly identity:', error);
        });
    }
  }, [props.jobDescription, ldClient]);

  useEffect(() => {
    if (identitySet) {
      const useTomtomMapsFlag = flags.useTomtomMaps ?? true;
      setMapType(useTomtomMapsFlag ? 'tomtom' : 'google');
    }
  }, [identitySet, flags]);

  useEffect(() => {
    if (mapType === 'google') {
      loadGoogleMapsScript()
        .then(() => {
          setIsGoogleMapLoaded(true);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [mapType]);

  useEffect(() => {
    if (mapType === 'tomtom' && typeof window !== 'undefined') {
      import('@tomtom-international/web-sdk-maps')
        .then((tomtom) => {
          const tomtomMap = tomtom.map({
            key: window.tomtomAPIKey,
            container: mapElement.current,
            style: `https://api.tomtom.com/style/2/custom/style/dG9tdG9tQEBAU3h2SlRhWURVaUhlSllLRzs1NjU4ZGRjZi0zNWRhLTRiMDYtOGQ5NS1hMTVjYzNlMDkzYWE=.json?key=${window.tomtomAPIKey}`,
            center: [center.lng, center.lat],
            zoom: 15,
          });

          tomtomMap.addControl(new tomtom.FullscreenControl());
          tomtomMap.addControl(
            new tomtom.NavigationControl({
              showPitch: true,
              showExtendedRotationControls: true,
              showExtendedPitchControls: true,
            }),
          );

          const customMarkerElement = document.createElement('div');
          customMarkerElement.style.backgroundImage = `url(${customMarker})`;
          customMarkerElement.style.backgroundSize = 'cover';
          customMarkerElement.style.width = '45px';
          customMarkerElement.style.height = '54px';

          const marker = new tomtom.Marker({ element: customMarkerElement })
            .setLngLat([center.lng, center.lat])
            .addTo(tomtomMap);

          marker.getElement().addEventListener('click', () => {
            window.open(url, '_blank');
          });

          return () => tomtomMap.remove();
        })
        .catch((error) => {
          console.error('Error rendering TomTom map:', error);
          setMapType('google');
        });
    }
  }, [mapType, center]);

  const renderGoogleMap = () => (
    <Styled.MapContainer>
      <Styled.SectionHeader>{t('location')}</Styled.SectionHeader>
      <Styled.MapLocationText>
        {props.jobDescription && props.jobDescription.address}
      </Styled.MapLocationText>
      {isGoogleMapLoaded && (
        <GoogleMap
          mapContainerStyle={{
            height: config.height,
            maxHeight: config.maxHeight,
          }}
          center={center}
          zoom={16}
          onError={() => {
            console.error('Error rendering Google map');
            setMapType('tomtom');
          }}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </Styled.MapContainer>
  );

  const renderTomTomMap = () => (
    <Styled.MapContainer>
      <Styled.SectionHeader>{t('location')}</Styled.SectionHeader>
      <Styled.MapLocationText
        onClick={() => {
          const address = props.jobDescription && props.jobDescription.address;
          if (address) {
            window.open(url, '_blank');
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        {props.jobDescription && props.jobDescription.address}
      </Styled.MapLocationText>
      <div
        ref={mapElement}
        style={{ height: config.height, maxHeight: config.maxHeight }}
      />
    </Styled.MapContainer>
  );

  if (!identitySet || mapType === null) return <div>Loading...</div>;

  if (error) return <div>Unable to display the map at this time.</div>;

  return mapType === 'tomtom' ? renderTomTomMap() : renderGoogleMap();
};

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

JobDescriptionMap.propTypes = {
  jobDescription: PropTypes.object,
};

export default connect(mapStateToProps, {})(JobDescriptionMap);