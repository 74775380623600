import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import CompanyProfileJobsFilters from 'CompanyProfileJobsFilters';
import { withTranslation } from 'react-i18next';
import * as Styled from 'companyProfileJobs_styled';
/* Styled Components */
import { Icon } from 'semantic-ui-react';
import { getCompanyProfileJobsAction } from '../../../../../actions/api_actions';
import ApploiBackfill from '../../../../../../../public/assets/img/ApploiBackfill.png';
import ApploiBoosted from '../../../../../../../public/assets/img/ApploiBoosted.png';
import ApploiRegular from '../../../../../../../public/assets/img/ApploiRegular.png';
import InfiniteScrollExtend from './InfiniteScrollExtend';
import ErrorBoundaryWrapper from '../../../../sharedComponents/errorPages/ErrorBoundary';
import { loadGoogleMapsScript } from '../../../GoogleMapUtils';

export class CompanyProfileJobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageStart: 1,
      hasMore: true,
      visibility: 'visible',
      isGoogleMapsScriptLoaded: false,
    };

    this.loadJobs = this.loadJobs.bind(this);
    this.handleScrollToTopAndRest = this.handleScrollToTopAndRest.bind(this);
    this.publishedDateSince = this.publishedDateSince.bind(this);
  }

  componentDidMount() {
    loadGoogleMapsScript().then(() => {
      this.setState({ isGoogleMapsScriptLoaded: true });
    });
  }

  loadJobs = (page) => {
    this.setState({
      hasMore: false,
    });
    const {
      companyProfile,
      companyProfileJobsFilters,
      allTeamsSearch,
      filteredIndustries,
      backfilledJobs,
      debug,
      i18n: { language },
      city,
      utm,
    } = this.props;

    this.props
      .getCompanyProfileJobsAction(
        window.activeEnvironment,
        page,
        companyProfileJobsFilters.searchbar,
        companyProfileJobsFilters.lat,
        companyProfileJobsFilters.lng,
        allTeamsSearch ? '' : companyProfile.teams_to_show,
        filteredIndustries,
        backfilledJobs,
        debug,
        language,
        city?.city,
        city?.state,
        companyProfileJobsFilters.radius,
        utm,
      )
      .then((response) => {
        if (response !== false) {
          /* If we get no data back we stop ability to scroll any further on infinite scroll */
          this.setState({
            hasMore: true,
          });
        }
      });
  };

  handleScrollToTopAndRest = () => {
    const infiniteScrollContainer = document.getElementById(
      'infinite-scroll-container',
    );
    if (infiniteScrollContainer) {
      // Scrolling the container to the top
      infiniteScrollContainer.scrollTop = 0;

      /*      We are hidding the jobs and showing them right away.
      The only reason is to indicate the user that search has
      been done and results have been updated. */
      this.setState(
        {
          visibility: 'hidden',
        },
        () => {
          setTimeout(() => {
            this.setState({ visibility: 'visible' });
          }, 1000);
        },
      );
      // reseting page count (conected to "pageLoaded" property in InfiniteScroll npm package )
      // reset hasMore to true so infinitscroll container is active again
      this.setState({
        pageStart: this.state.pageStart === 1 ? -1 : 1,
        hasMore: true,
      });
    }
  };

  publishedDateSince = (job) => {
    const { t } = this.props;
    const startDate = moment(job.published_date, 'YYYY-MM-DD').format(
      'YYYY,MM,DD',
    );
    const endDate = moment(new Date()).format('YYYY,MM,DD');

    const startDateMoment = moment(startDate, 'YYYY-MM-DD').unix();
    const endDateMoment = moment(endDate, 'YYYY-MM-DD').unix();
    const dayCount = Math.round((endDateMoment - startDateMoment) / 86400);
    if (dayCount > 1) {
      return `${dayCount} ${t('companyProfileDaysAgo')}`;
    }
    if (dayCount === 1) {
      return `${dayCount} ${t('companyProfileDayAgo')}`;
    }
    if (dayCount === 0) {
      return t('companyProfileToday');
    }
  };

  renderName = (job, i18n) => {
    /* Remove everything after ' - ' in name. We give the same warning message in truman when editing the job */
    const nameToShow = i18n.language === 'en' ? job.name : job.name_es;
    const cleanName = nameToShow ? nameToShow.split(' - ')[0] : '';
    return cleanName;
  };

  renderJsonViwer = (props) => {
    if (typeof window !== 'undefined') {
      const ReactJson = require('react-json-view').default;
      return <ReactJson style={{ marginTop: '1rem' }} {...props} />;
    }
    return null;
  };

  renderApploiIcon = (type, totalScore, debug) => {
    let imagetoShow;
    switch (type) {
      case 'backfillingjob':
        imagetoShow = (
          <Styled.JobApploiImage src={ApploiBackfill} alt="backfillingjob" />
        );
        break;
      case 'preferredjob':
        imagetoShow = (
          <Styled.JobApploiImage src={ApploiBoosted} alt="preferredjob" />
        );
        break;
      case 'job':
        imagetoShow = <Styled.JobApploiImage src={ApploiRegular} alt="job" />;
        break;

      default:
        break;
    }

    return (
      <Styled.JobApploiImageContainer>
        {imagetoShow}
        {debug && <span>Total: {totalScore}</span>}
      </Styled.JobApploiImageContainer>
    );
  };

  renderScores = (job) => {
    const { _distance, _factor_distance, _total_boost, _boost_explanation } =
      job;

    return (
      <Styled.JobScoresContainer>
        <span>{_distance}</span>&nbsp;-&nbsp;
        <span>{_factor_distance}</span>,&nbsp;&nbsp;
        <span>
          Unadjusted total: {_total_boost} ({_boost_explanation})
        </span>
      </Styled.JobScoresContainer>
    );
  };

  render() {
    const { isGoogleMapsScriptLoaded } = this.state;

    const {
      companyProfile,
      companyProfileJobs,
      allTeamsSearch,
      backfilledJobs,
      debug,
      updateJobs,
      t,
      i18n,
      routeData,
      utm,
    } = this.props;
    const items = [];
    companyProfileJobs.forEach((job, index) => {
      const { doc_type } = job;
      items.push(
        <Styled.JobContainer key={job.id} visibility={this.state.visibility}>
          {debug && (
            <React.Fragment>
              {job.backfilled && <Icon name="briefcase" />}
              <b>{`${index + 1} )`}</b>
            </React.Fragment>
          )}
          <Styled.JobInfoContainer>
            <Styled.JobLocationInfo>
              <div>
                {job.city}, {job.state} | {this.publishedDateSince(job)} |{' '}
                {t(job.job_type)}
              </div>
            </Styled.JobLocationInfo>

            <Styled.JobInfoName
              rel="noreferrer"
              target="_blank"
              href={
                allTeamsSearch
                  ? job?.redirect_apply_url
                  : `/view/${job.id}${
                      window.location.search ||
                      '?utm_campaign=bolivar&utm_source=company_profile_page'
                    }`
              }
            >
              {this.renderName(job, i18n)}
              {routeData.key === 'search' &&
                this.renderApploiIcon(doc_type, job._score, debug)}
              {debug && this.renderScores(job)}
            </Styled.JobInfoName>
            {debug &&
              this.renderJsonViwer({
                name: 'More',
                src: job?.es_explanation,
                collapsed: true,
                enableClipboard: false,
                displayObjectSize: false,
                displayDataTypes: false,
              })}
          </Styled.JobInfoContainer>
          <div
            style={{
              width: '20%',
              textAlign: 'center',
              margin: '0 5px',
            }}
          >
            {/* Link needs to be instead of href here */}
            <a
              rel="noreferrer"
              target="_blank"
              href={
                allTeamsSearch
                  ? job?.redirect_apply_url
                  : `/view/${job.id}${
                      window.location.search ||
                      '?utm_campaign=bolivar&utm_source=company_profile_page'
                    }`
              }
            >
              <Styled.JobApplyButton
                customcolor={
                  !allTeamsSearch &&
                  companyProfile &&
                  companyProfile.primary_color
                    ? companyProfile.primary_color
                    : '#000'
                }
              >
                {t('companyProfileApply')}
              </Styled.JobApplyButton>
            </a>
          </div>
        </Styled.JobContainer>,
      );
    });
    return (
      <Styled.FilterJobsContainer>
        <ErrorBoundaryWrapper
          tags={{ component: 'CompanyProfileJobsFilter' }}
          sectionName="Jobs Filters"
        >
          {isGoogleMapsScriptLoaded ? (
            <CompanyProfileJobsFilters
              scrollToTop={() => this.handleScrollToTopAndRest()}
              allTeamsSearch={allTeamsSearch}
              backfilledJobs={backfilledJobs}
              debug={debug}
              updateJobs={updateJobs}
              utm={utm}
            />
          ) : (
            <div>Loading Google Maps...</div>
          )}
        </ErrorBoundaryWrapper>
        {companyProfileJobs && companyProfileJobs.length > 0 ? (
          <Styled.JobsListContainer
            id="infinite-scroll-container"
            ref={(ref) => (this.scrollParentRef = ref)}
            key={0}
            allTeamsSearch={allTeamsSearch}
          >
            <ErrorBoundaryWrapper
              tags={{ component: 'InfiniteScrollExtend' }}
              sectionName="Jobs"
            >
              <InfiniteScrollExtend
                className="threshold-fix" // without this and threshold property it happens that loadMore keeps triggering if there are small amount of jobs
                data-testid="infinity-scroll"
                loadMore={this.loadJobs}
                hasMore={this.state.hasMore}
                threshold={1}
                pageStart={this.state.pageStart}
                useWindow={false}
                getScrollParent={() => this.scrollParentRef}
              >
                {items}
              </InfiniteScrollExtend>
            </ErrorBoundaryWrapper>
          </Styled.JobsListContainer>
        ) : companyProfileJobs && companyProfileJobs.length === 0 ? (
          <Styled.NoJobsContainer>
            CURRENTLY NO JOBS AVAILABLE
          </Styled.NoJobsContainer>
        ) : (
          <p>Loading...</p>
        )}
        {this.state.jobLoader && <p>Loading...</p>}
      </Styled.FilterJobsContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  companyProfile: state.companyProfileReducer.companyProfile,
  companyProfileJobs: state.companyProfileReducer.companyProfileJobs,
  companyProfileJobsFilters:
    state.companyProfileReducer.companyProfileJobsFilters,
  filteredIndustries: state.globalReducer.filteredIndustries,
  city: state.globalReducer.city,
  routeData: state.globalReducer.routeData,
});

CompanyProfileJobs.propTypes = {
  // redux
  companyProfile: PropTypes.object,
  companyProfileJobs: PropTypes.array,
  companyProfileJobsFilters: PropTypes.object,
};

export default connect(mapStateToProps, { getCompanyProfileJobsAction })(
  withTranslation('internal')(CompanyProfileJobs),
);
